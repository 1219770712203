<!-- here login-wrapper class is added only if the displayBiometricsSetup is false
because login-wrapper takes 100% height and displays grey background.

And since the displayBiometricsSetup with true value shows the <app-face-id> parameter inside the login-wrapper div,
the app-face-id component takes the background of login as grey. This is not in the design-->
<div *ngIf="loginPage; else loading"  [ngClass]="{'login-wrapper' : !displayBiometricsSetup}">
  <div  *ngIf="emailConfirmed; else confirmEmail">
    <section class="login" *ngIf="!displayBiometricsSetup; else biometrics">
      <div class="signin-dialog">
        <form class="form" [formGroup]="loginForm" (ngSubmit)="loginFormSubmit()" *ngIf="!forceChangePassword && !mfaForceEnterPhoneNumber && !mfaForceEnterOTP && !mfaInteractionRequired && !mfaFirstActivationView">
          <div class="logo text-center mb-4">
            <img width="180px" alt="eachperson-logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png" />
          </div>
          <h5 class="text-center mb-3" *ngIf="!credentialsChanged">Sign in With Your <br>
          Email and Password</h5>
          <h5 class="text-center mb-3" *ngIf="credentialsChanged">Sign in</h5>
          <p class="credential-changed-class" *ngIf="credentialsChanged">Email/Password has changed, please
            login to resume Face/Touch ID.</p>
          <div *ngIf="errorMessage" class="alert alert-danger text-center">
            {{ errorMessage }}
          </div>
          <label for="signInFormUsername" class="label-customizable pb-0 mb-1">Email</label>
          <input id="signInFormUsername" name="text" type="text" class="form-control inputField-customizable"
                 placeholder="Email" formControlName="email"
                 [ngClass]="{ 'is-invalid' : !loginForm.get('email').valid && (loginForm.get('email').touched || loginForm.get('email').dirty)}"
                 title="Enter your email address">
          <small class="form-text text-danger"
                 *ngIf="!loginForm.get('email').valid && (loginForm.get('email').touched || loginForm.get('email').dirty)">Please
            enter your email.</small>

          <label for="signInFormPassword" class="label-customizable mt-3 mb-1">Password</label>
          <div class="customised-password">
            <input id="signInFormPassword" (ngModelChange)= "errorMessage = null" name="password" [type]=" showPassword ? 'text' : 'password' "
                   class="form-control inputField-customizable" placeholder="Password" formControlName="password"
                   [ngClass]="{ 'is-invalid' : !loginForm.get('password').valid && (loginForm.get('password').touched || loginForm.get('password').dirty) }" title="Enter your password">
            <i-feather [name]="!showPassword ? 'eye' : 'eye-off'" class="text-secondary icon-sm customise-icon"
                       (click)="changeKeyType()"></i-feather>

            <small class="form-text text-danger"
                   *ngIf="!loginForm.get('password').valid && (loginForm.get('password').touched || loginForm.get('password').dirty)">Please
              enter your password</small>
          </div>

          <input name="signInSubmitButton" type="submit" value="Sign in"
          class="btn btn-primary submitButton-customizable" aria-label="submit"
          [disabled]="!loginForm.valid || loginSubmitting">
          <div class="my-3 text-center" aria-hidden="true">
            <a routerLink="/forgot-password" [queryParams]="{email: this.loginForm.get('email').value}" class="text-center">Forgot your password?</a>
          </div>

          <div class="logo text-center mt-1" aria-hidden="true">
            <img width="80px" alt="eachperson-logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png" class="logo-customise" /> @ Each Person
            {{ currentYear }}
          </div>
          <div class="logo text-center" aria-hidden="true">
            Powered by <img width="38px" alt="epoints-logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/epoints.png" />
          </div>
        </form>

        <!-- if the user is newly created and the login response has data that tells the user has to set new password, then
        make forceChangePassword=true and enable force-change-password view -->
        <app-force-change-password *ngIf="forceChangePassword && !mfaForceEnterPhoneNumber && !mfaForceEnterOTP && !mfaInteractionRequired && !mfaFirstActivationView" [errorMessage]="errorMessage" [loginSubmitting]="loginSubmitting" (firstTimePassword)="loginFormSubmit($event)" [currentYear]="currentYear"></app-force-change-password>
        
        <app-mfa *ngIf="(mfaForceEnterPhoneNumber || mfaForceEnterOTP || mfaInteractionRequired || mfaFirstActivationView) && !forceChangePassword"
        [mfaForceEnterPhoneNumber]="mfaForceEnterPhoneNumber"
        [mfaForceEnterOTP]="mfaForceEnterOTP"
        [mfaInteractionRequired]="mfaInteractionRequired"
        [mfaFirstActivationView]="mfaFirstActivationView"
        [mfaPartialPhoneNumber]="mfaPartialPhoneNumber"
        [errorMessage]="errorMessage"
        [loginSubmitting]="loginSubmitting"
        (mfaAction)="loginFormSubmit($event)"
        [currentYear]="currentYear"
        [email]="userCredentials?.email"></app-mfa>
      </div>
    </section>
  </div>
</div>

<ng-template #loading>
    <div class="d-flex flex-column justify-content-center align-items-center" style="height: 100vh;">
      <div class="spinner-border" role="status">
        <span class="visually-hidden"></span>
      </div>
      <p>{{ 'Redirecting ...' | translate }}</p>
    </div>
</ng-template>

<ng-template #confirmEmail>
  <app-check-sso-signin (emailConfirmed)="onEmailConfirmed($event)"
  (biometricLogin)="onBiometricLogin($event)"
  [errorMessage]="errorMessage"
  [invalidLoginCreds]="invalidLoginCreds"
  (credentialsChanged)="onCredentialsChanged($event)" [redirect_uri]='redirect_uri' [response_type]="response_type" [state]="state" [currentYear]="currentYear"></app-check-sso-signin>
</ng-template>

<ng-template #biometrics>
  <app-face-id></app-face-id>
</ng-template>

