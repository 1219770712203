<form *ngIf="(mfaFirstActivationView && mfaForceEnterOTP) || (mfaInteractionRequired && !mfaForceEnterPhoneNumber && !mfaForceEnterOTP)" class="form mfa-container-form" [formGroup]="mfaInteractionForm" (ngSubmit)="submitForceMfa()" autocomplete="off">
    <div class="mfa-close-icon">
        <span (click)="closeMfaWindow()">
            <svg width="9px" height="9px" viewBox="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="DASHBOARD-(Social-Design)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                stroke-linecap="round" stroke-linejoin="round">
                <g id="-Dashboard-/-Social-/-Emoges-Reaction-Section-2---Desk" transform="translate(-929.5, -286.5)"
                stroke="#212322" stroke-width="2">
                <g id="Popup-/-Emojis-Reactions" transform="translate(483, 266)">
                    <g id="x" transform="translate(446.5, 20.5)">
                    <line x1="9" y1="0" x2="0" y2="9" id="Path"></line>
                    <line x1="0" y1="0" x2="9" y2="9" id="Path"></line>
                    </g>
                </g>
                </g>
            </g>
            </svg>
        </span>
    </div>
    <h5 class="text-center mt-3 mb-3">Two-Step<br/>Verification</h5>
    <p class="font-weight-normal text-center">Protect your account by adding an extra layer of security.</p>

    <div class="pb-0 pt-0 d-flex justify-content-center align-items-center" [ngClass]="{'form-disable': mfaInteractionEnterPhoneNumber || mfaForceEnterOTP}">
        <span>
            Enable
        </span>
        <span>		
            <label class="custom-switch mt-2">
                <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" formControlName="mfaStatus" (ngModelChange)="toggleMfaStatus($event)">
                <span class="custom-switch-indicator"></span>
            </label>
        </span>
    </div>
    <input *ngIf="!mfaInteractionEnterPhoneNumber && !mfaForceEnterOTP" name="mfaEnableConfirmButton" type="button" value="Confirm" 
        class="btn btn-secondary submitButton-customizable" aria-label="submit" (click)="mfaStatusConfirm()"
        [disabled]="loginSubmitting">
    
    <!-- start: mobile number input -->
    <div class="custom-phone-input-wrapper" *ngIf="mfaInteractionEnterPhoneNumber && !mfaForceEnterOTP">
        <label for="mfaPhoneNumber" class="label-customizable mt-3 mb-1 font-weight-bold">Mobile Number<span class="text-danger">*</span></label>
        <div class="input-group">
            <!-- <div class="input-group-prepend">
                <div class="input-group-text" [ngClass]="{'is-invalid': !mfaInteractionForm.get('phoneNumber').valid && (mfaInteractionForm.get('phoneNumber').touched || mfaInteractionForm.get('phoneNumber.dirty')) }">{{ selectedCountryCode }}</div>
            </div> -->
            <ngx-intl-tel-input 
                [cssClass]="'custom-phone-input' + (!mfaInteractionForm.get('phoneNumber').valid && (mfaInteractionForm.get('phoneNumber').touched || mfaInteractionForm.get('phoneNumber.dirty')) ? ' is-invalid' : '')" 
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true" 
                [enablePlaceholder]="true"
                [customPlaceholder]="customPlaceHolder"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" 
                [selectFirstCountry]="true"
                [maxLength]="15"
                [phoneValidation]="true" 
                [separateDialCode]="separateDialCode"
                [numberFormat]="PhoneNumberFormat.National" 
                name="phone" 
                formControlName="phoneNumber"
                (countryChange)="onCountryChange($event)" 
            ></ngx-intl-tel-input>
        </div>
    </div>
    <div *ngIf="mfaInteractionEnterPhoneNumber && !mfaForceEnterOTP">
        <small class="form-text text-danger" *ngIf="!mfaInteractionForm.get('phoneNumber').valid && (mfaInteractionForm.get('phoneNumber').touched || mfaInteractionForm.get('phoneNumber.dirty'))">Please enter a valid phone number.</small>
    </div>
    <input *ngIf="mfaInteractionEnterPhoneNumber && !mfaForceEnterOTP" name="sendCodeButton" type="submit" value="Send code" 
    class="btn btn-secondary submitButton-customizable" aria-label="submit"
    [disabled]="!mfaInteractionForm.get('phoneNumber').valid || loginSubmitting"
    >
    <!-- end: mobile number input -->
    
    <!-- start: verification code -->
    <p class="font-weight-normal text-center gray-box" *ngIf="mfaForceEnterOTP">Enter the verification code sent to your mobile number ending in ({{ mfaPartialPhoneNumber }})</p>
    <div *ngIf="errorMessage" class="alert alert-danger text-center custom-alert-danger">
        <svg class="mb-2" width="35" height="35" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#FFF" fill="none" fill-rule="evenodd">
                <circle stroke-width="2" cx="17.5" cy="17.5" r="16.5" />
                <g stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
                    <path d="m13 12 9.119 10M22.119 12 13 22" />
                </g>
            </g>
        </svg> <br />
        {{ errorMessage }}
    </div>
    
    <label *ngIf="mfaForceEnterOTP">Verification Code</label>
    <div class="customised-password" *ngIf="mfaForceEnterOTP">
        <input id="otpPhoneNumber" (ngModelChange)="errorMessage = null" name="otpCode"
            class="form-control inputField-customizable"
            placeholder="Enter Code" formControlName="otpCode">
    </div>
    <div *ngIf="mfaForceEnterOTP">
        <div class="my-2 text-center" aria-hidden="true" *ngIf="countdown > 0">
            <p class="text-center text-muted font-weight-normal">Resend code in {{ countdown }} {{ countdown === 1 ? "second" : "seconds" }}</p>
        </div>
        <div class="my-2 text-center" aria-hidden="true" *ngIf="countdown <= 0">
            <a (click)="resendMfaCode()" class="text-center">Resend code</a>
        </div>
    </div>
    <input *ngIf="mfaForceEnterOTP" name="codeVerifyConfirmButton" type="submit" value="Confirm" 
        class="btn btn-secondary submitButton-customizable" aria-label="submit"
        [disabled]="loginSubmitting && !mfaInteractionForm.valid">
    <!-- end: verification code -->

    <div class="my-3 text-center" aria-hidden="true">
        <a class="text-center" (click)="submitForceMfa(false, true)">Skip</a>
    </div>
</form>

<form *ngIf="mfaForceEnterPhoneNumber && !mfaForceEnterOTP && !mfaInteractionRequired && !mfaFirstActivationView" class="form" [formGroup]="phoneForm" (ngSubmit)="submitForceMfa()" autocomplete="off">
    <div class="logo text-center mb-4">
        <img width="180px" alt="logo"
            src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png" />
    </div>
    <h5 class="text-center mb-3">Keeping you secure</h5>
    <p class="font-weight-normal text-center gray-box">Enter your phone number to receive a code for verification.</p>
    <div *ngIf="errorMessage" class="alert alert-danger text-center custom-alert-danger">
        <svg class="mb-2" width="35" height="35" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#FFF" fill="none" fill-rule="evenodd">
                <circle stroke-width="2" cx="17.5" cy="17.5" r="16.5" />
                <g stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
                    <path d="m13 12 9.119 10M22.119 12 13 22" />
                </g>
            </g>
        </svg> <br />
        {{ errorMessage }}
    </div>

    <div class="custom-phone-input-wrapper">
        <label for="mfaPhoneNumber" class="label-customizable mt-3 mb-1 font-weight-bold">Phone Number</label>
        <div class="input-group">
            <!-- <div class="input-group-prepend">
                <div class="input-group-text" [ngClass]="{'is-invalid': !phoneForm.get('phoneNumber').valid && (phoneForm.get('phoneNumber').touched || phoneForm.get('phoneNumber.dirty')) }">{{ selectedCountryCode }}</div>
            </div> -->
            <ngx-intl-tel-input 
                [cssClass]="'custom-phone-input' + (!phoneForm.get('phoneNumber').valid && (phoneForm.get('phoneNumber').touched || phoneForm.get('phoneNumber.dirty')) ? ' is-invalid' : '')" 
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true" 
                [enablePlaceholder]="true"
                [customPlaceholder]="customPlaceHolder"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" 
                [selectFirstCountry]="true"
                [maxLength]="15"
                [phoneValidation]="true" 
                [separateDialCode]="separateDialCode"
                [numberFormat]="PhoneNumberFormat.National" 
                name="phone" 
                formControlName="phoneNumber"
                (countryChange)="onCountryChange($event)" 
            ></ngx-intl-tel-input>
        </div>
    </div>

    <div *ngIf="!phoneForm.get('phoneNumber').valid && (phoneForm.get('phoneNumber').touched || phoneForm.get('phoneNumber.dirty'))">
        <small class="form-text text-danger">Please enter a valid phone number.</small>
    </div>

    <input name="mfaSubmitButton" type="Submit" value="Send code"
    [disabled]="!phoneForm.valid || loginSubmitting"
        class="btn btn-primary submitButton-customizable" aria-label="submit">
    <div class="my-3 text-center" aria-hidden="true">
        <a routerLink="/forgot-password" [queryParams]="{email: email}" class="text-center">Forgot your password?</a>
    </div>

    <div class="logo text-center mt-3">
        <img width="80px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png"
            class="logo-customise" /><span> @ Each
            Person {{ currentYear }}</span>
    </div>
    <div class="logo text-center">
        Powered by <img width="38px" alt="logo"
            src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/epoints.png" />
    </div>
</form>

<form *ngIf="!mfaFirstActivationView && mfaForceEnterOTP && !mfaForceEnterPhoneNumber && !mfaInteractionRequired" class="form" [formGroup]="mfaForm" (ngSubmit)="submitForceMfa()" autocomplete="off">
    <h5 class="text-center mt-3 mb-3">Two-Step<br />Verification</h5>
    <!-- <p class="font-weight-normal text-center">Protect your account by adding an extra layer of security.</p> -->
    <p class="font-weight-normal text-center gray-box">Enter verification code sent to your mobile ending ({{ mfaPartialPhoneNumber }})</p>
    <div *ngIf="errorMessage" class="alert alert-danger text-center custom-alert-danger">
        <svg class="mb-2" width="35" height="35" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#FFF" fill="none" fill-rule="evenodd">
                <circle stroke-width="2" cx="17.5" cy="17.5" r="16.5" />
                <g stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
                    <path d="m13 12 9.119 10M22.119 12 13 22" />
                </g>
            </g>
        </svg> <br />
        {{ errorMessage }}</div>

    <div class="customised-password">
        <input id="otpPhoneNumber" (ngModelChange)="errorMessage = null" name="otpCode"
            class="form-control inputField-customizable"
            placeholder="OTP Code" formControlName="otpCode">
    </div>
    <div class="my-2 text-center" aria-hidden="true" *ngIf="countdown > 0">
        <p class="text-center text-muted font-weight-normal">Resend code in {{ countdown }} seconds</p>
    </div>
    <div class="my-2 text-center" aria-hidden="true" *ngIf="countdown <= 0">
        <a (click)="resendMfaCode()" class="text-center">Resend code</a>
    </div>

    <input name="otpSubmitButton" type="Submit" value="Continue" class="btn btn-primary submitButton-customizable"
    [disabled]="!mfaForm.valid || loginSubmitting"
        aria-label="submit">
    <div class="my-2 text-center" aria-hidden="true">
        <a routerLink="/forgot-password" [queryParams]="{email: email}" class="text-center">Forgot your password?</a>
    </div>

    <div class="logo text-center mt-3">
        <img width="80px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png"
            class="logo-customise" /><span> @ Each
            Person {{ currentYear }}</span>
    </div>
    <div class="logo text-center">
        Powered by <img width="38px" alt="logo"
            src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/epoints.png" />
    </div>
</form>