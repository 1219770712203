<footer class="main-footer" *ngIf="!load; else loading" >
    <div class="main-footer-inner w-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <h4 class="widget-title d-flex justify-content-between"  (click)="onToggle('acc')">
                        <span>{{ 'My Account' | translate }}</span>
                        <span class="d-block d-lg-none">
                            <i-feather [name]="toggleObj.account ? 'minus':'plus'"></i-feather>
                        </span>
                    </h4>
                    <ul class="d-none d-lg-block" [ngClass]="toggleObj.account ? 'd-block':'d-none'">
                        <li><a href="{{ accountsUrl.details }}">{{ 'Profile' | translate }}</a></li>
                        <li><a href="{{ accountsUrl.orders }}">{{ 'Orders' | translate }}</a></li>
                        <li><a href="{{ accountsUrl.activity }}">{{ 'Activity' | translate }}</a></li>
                        <li><a href="{{ accountsUrl.addresses }}">{{ 'Addresses' | translate }}</a></li>
                        <li><a routerLink="/hr/history/users-allocation">{{ 'Recognition History' | translate }}</a></li>
                    </ul>
                </div>
                <div class="col-lg-3">
                    <h4 class="widget-title d-flex justify-content-between" (click)="onToggle('act')">
                        <span>{{ 'Actions' | translate }}</span>
                        <span class="d-block d-lg-none" >
                            <i-feather [name]="toggleObj.action ? 'minus':'plus'"></i-feather>
                        </span>
                    </h4>
                    <ul class="d-none d-lg-block" [ngClass]="toggleObj.action ? 'd-block':'d-none'">
                        <li><a routerLink="/hr/points-allocation/choose-an-ecard">{{ 'Send an ecard' | translate }}</a></li>
                        <li><a routerLink="/hr/points-allocation/nominate">{{ 'Nominate a colleague' | translate }}</a></li>
                        <li *ngIf="!userFlags?.onlyDashboard"><a href="javascript:void(0)" (click)="goToShopGiftCards()">{{ 'Shop - gift cards' | translate }}</a></li>
                        <li *ngIf="!userFlags?.onlyDashboard"><a href="javascript:void(0)" (click)="goToShopProducts()">{{ 'Shop - products' | translate }}</a></li>
                        <li *ngIf="showDonationFooter"><a routerLink="/hr/donate">{{ 'Donate' | translate }}</a></li>
                    </ul>
                </div>
                <div class="col-lg-3">
                    <h4 class="widget-title d-flex justify-content-between" (click)="onToggle('red')">
                        <span>{{ 'Read More' | translate }}</span>
                        <span class="d-block d-lg-none" >
                            <i-feather [name]="toggleObj.readMore ? 'minus':'plus'"></i-feather>
                        </span>
                    </h4>
                    <ul class="d-none d-lg-block" [ngClass]="toggleObj.readMore? 'd-block':'d-none'">
                        <li><a routerLink="/hr/points-allocation/wellbeing">{{ 'Wellbeing Hub' | translate }}</a></li>
                        <li><a href="https://www.eachperson.com/blog/" [target]="authService.insideGoNativeWrapper()">{{ 'Each Person Blog' | translate }}</a></li>
                        <li><a href="https://surveys.hotjar.com/3733e439-c18b-4998-9388-77711bc5398f" [target]="authService.insideGoNativeWrapper()">{{ 'Creation Club' | translate }}</a></li>
                        <li><a href="https://support.eachperson.com/" [target]="authService.insideGoNativeWrapper()">{{ 'Knowledge Base' | translate }}</a></li>
                        <li><a href="https://www.eachperson.com/assets/" [target]="authService.insideGoNativeWrapper()">{{ 'Assets' | translate }}</a></li>
                    </ul>
                </div>
                <div class="col-lg-3">
                    <h4 class="widget-title d-flex justify-content-between"  (click)="onToggle('leg')" >
                        <span>{{ 'Legal' | translate }}</span>
                        <span class="d-block d-lg-none">
                            <i-feather [name]="toggleObj.legal ? 'minus':'plus'"></i-feather>
                        </span>
                    </h4>
                    <ul class="d-none d-lg-block" [ngClass]="toggleObj.legal ? 'd-block':'d-none'">
                        <li><a href="#" (click)="goToExternalLink($event, 'terms-conditions')">{{ 'Terms & Conditions' | translate }}</a></li>
                        <li><a href="#" (click)="goToExternalLink($event, 'privacy-policy')">{{ 'Privacy Policy' | translate }}</a></li>
                        <li><a href="https://www.eachperson.com/cookie-policy/" [target]="authService.insideGoNativeWrapper()">{{ 'Cookies' | translate }}</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-icons">
                <a href="https://twitter.com/_eachperson" [target]="authService.insideGoNativeWrapper()"><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/twitter-icon.svg" height="20px" width="24px"></a>
                <a href="https://www.linkedin.com/company/each-person/ " [target]="authService.insideGoNativeWrapper()"><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/linkedin-icon.svg" height="22px" width="22px"></a>
            </div>
            <div class="copyright-text">
                <p>{{ 'Copyright' | translate }} &copy; {{ currentYear }} • Each Person</p>
            </div>
        </div>
    </div>
</footer>
<ng-template #loading>
    <div class="fullscreen-loader">
        <div class="spinner-border" role="status">
            <span class="visually-hidden"></span>
        </div>
        <!-- <p>Redirecting ...</p> -->
    </div>
</ng-template>
