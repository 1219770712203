import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import { AuthService } from "src/app/core/service/auth.service";
import { getAccountsBaseURL, getWPShopDomain } from "src/app/core/_helpers/environment-variables";
import { environment } from "src/environments/environment";
import { take, finalize } from "rxjs/operators";
import {setCred} from "../../core/_helpers/gonativeGlobal";
import { GonativeGlobal } from "src/assets/custom-JS/gonative-global-class.js";
import { ToastrCustomMessageService } from "src/app/core/service/toastr-custom-message.service";
import { encryptToken, setEPCookie } from "src/app/core/_helpers/global-functions";

declare let gonative_onesignal_info: any; // this data is used as global function variable

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showPassword: any = false;
  password: any = "";
  loginCode: string = null;
  redirect_uri: string;
  response_type: string;
  state: string;
  url: string = environment.apiUrl;
  loginSubmitting: boolean = false;
  errorMessage: string = "";
  
  loginPage: boolean = true;

  forceChangePassword: boolean = false;
  loginChallengeName: string = "";
  loginSessionToken: string = "";
  
  emailConfirmed: boolean = false;

  currentYear: number = null;
  displayBiometricsSetup: boolean = false
  credentialsChanged: boolean = false
  userCredentials: any = {
    email: null,
    password: null
  }
  invalidLoginCreds: boolean = false
  isBiometricLogin: boolean = false
  responseFromLogin: any = null

  // MFA start
  mfaInteractionRequired: boolean = false;
  mfaFirstActivationView: boolean = false;
  mfaForceEnterPhoneNumber: boolean = false;
  mfaForceEnterOTP: boolean = false;
  mfaPartialPhoneNumber: string = "";
  // MFA end

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrCustomMessageService
  ) {}

  ngOnInit(): void {
    // remove user lock status if the user accesses the login page.
    if(this.authService.getIsUserLocked()) {
      this.authService.removeUserLockStatus();
    }

    this.handleRedirectLoginFromOtherApp();
    if (!!this.authService.getLoginSession()?.token) {
      this.loginPage = false;

      // set token in the cookie
      if(this.authService.getLoginSession()?.token && this.authService.getLoginSession()?.refreshToken) {
        setEPCookie('access_token', encryptToken(this.authService.getLoginSession()?.token));
        setEPCookie('refresh_token', encryptToken(this.authService.getLoginSession()?.refreshToken));
      }

      // if there is already token in localStorage, then check for token validity
      this.authService.checkTokenValidity().subscribe(
        response => {
          // if the token is valid, do reidirection accoridingly
          this.handleTokenValidCases();
        },
        error => {
          // if the token is invalid, then enable login page
          // try to refresh and fetch new token
          this.authService.refreshToken().subscribe(
            response => {
              this.handleTokenValidCases(response?.refresh_token);
            }, error => {
              // in case of token invalidity, remove token from localStorage
              this.loginPage = true;
              this.authService.removeLoginTokens();
              localStorage.removeItem("currentUser");
            }
          )

        }
      )
    } else {
      // if there is no token in the localStorage, then enable login page
      this.loginPage = true;
    }

    this.loginForm = new FormGroup({
      email: new FormControl({value: "", disabled: true}, [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
    });

    this.getCurrentYear();
  }

  getCurrentYear() {
    this.currentYear = (new Date()).getFullYear();
  }

  handleTokenValidCases(refreshToken?: string) {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    // if the user is not sso user, response_type is not token and there is redirect_uri, then fetch code and redirect
    if(this.redirect_uri && !(this.response_type || this.response_type === "token") && !this.authService.getSsoUser() && user) {
      this.authService
        .seamlessLogin(refreshToken || this.authService.getRefreshToken())
        .subscribe(
          (response) => {
            let redirectionUrl = `${this.redirect_uri}?code=${
              response.authenticationCode
            }${this.state ? `&state=${this.state}` : ""}`;
            
            if(this.redirect_uri === getWPShopDomain() || this.redirect_uri ===  `${getWPShopDomain()}/`) {
              let shop_url = this.redirect_uri === getWPShopDomain() ? `${this.redirect_uri}/` : this.redirect_uri;
              redirectionUrl = `${shop_url}?code=${
                response.authenticationCode
              }${this.state ? `&redirect_to=${encodeURIComponent(this.state)}` : ""}`;
            }
            window.location.href = redirectionUrl;
          },
          (error) => {
            console.log('cannot exchange code: ', error)
          }
        );
    // if the user is sso user, there is redirect_uri and no response_token, then redirect to SSO route of accounts
    } else if(this.redirect_uri && this.authService.getSsoUser() && !(this.response_type || this.response_type === "token")) {
      this.handleOtherAppsSsoRedirection()
    // if there is redirect_uri and response_type is token, handled in doRedirection function
    } else {
      if(!user) {
        this.fetchMeAPI();
      } else {
        this.authService.doRedirection(user);
      }
    }
  }

  changeKeyType() {
    this.showPassword = !this.showPassword;
  }

  handleRedirectLoginFromOtherApp() {
    this.route?.queryParams?.subscribe((params) => {
      this.redirect_uri = params["redirect_uri"] || null;
      this.response_type = params["response_type"] || null;
      this.state = params["state"] || null;

      // function to save the redirect_uri and state in localStorage
      this.authService.saveOtherAppsRedirectURI(
        this.redirect_uri,
        this.state,
        this.response_type
      );
    });
  }

  loginFormSubmit(event?: any) {
    this.errorMessage = null
    this.loginSubmitting = true;
    this.invalidLoginCreds = false
    this.userCredentials = {
      email: this.loginForm.get("email").value,
      password: this.loginForm.get("password").value.trim()
    };

    // start: for gonative - biometric login
    if (event && typeof(event) === 'object' && event?.email && event?.password){
      event.password = event.password.trim();
      this.userCredentials = event;

      // email and password are set in the form field so that in the next login with MFA the form's email and password are correct
      this.loginForm.get("email").setValue(this.userCredentials?.email);
      this.loginForm.get("password").setValue(this.userCredentials?.password);
    } // end: for gonative - biometric login

    // start: this event comes with password when the first time logging in user changes password
    else if (event && typeof(event) === 'object' && event?.changedPassword){
      this.userCredentials["challengeName"] = this.loginChallengeName;
      this.userCredentials["session"] = this.loginSessionToken;
      this.userCredentials.password = event?.changedPassword.trim();

      // the password's value in the form is changed because, the changed value is not reflecting in the MFA journey
      this.loginForm.get("password").setValue(this.userCredentials?.password);
    }
    // end: this event comes with password when the first time logging in user changes password

    // start: this event gives the mfaStatus and phone number if MFA interaction is not done
    else if (event && typeof(event) === 'object' && event?.mfaStatus){
      this.userCredentials["challengeName"] = this.loginChallengeName;
      this.userCredentials["mfaStatus"] = event?.mfaStatus;
      if(event?.mfaStatus === 'ENABLED') {
        this.userCredentials.phoneNumber = event?.phoneNumber.trim();
      }
    }
    // end: this event gives the mfaStatus and phone number if MFA interaction is not done

    // start: this event comes with the phone number entered by the user required for MFA if the phone number is not registered already
    else if (event && typeof(event) === 'object' && event?.phoneNumber){
      this.userCredentials["challengeName"] = this.loginChallengeName;
      this.userCredentials["session"] = this.loginSessionToken;
      this.userCredentials.phoneNumber = event?.phoneNumber.trim();
    }
    // end: this event comes with the phone number entered by the user required for MFA if the phone number is not registered already
    
    // start: this event comes with the mfa entered by the user
    else if (event && typeof(event) === 'object' && event?.mfaCode){
      this.userCredentials["challengeName"] = this.loginChallengeName;
      this.userCredentials["session"] = this.loginSessionToken;
      this.userCredentials.code = event?.mfaCode.trim();
    }
    // end: this event comes with the mfa entered by the user

    this.authService.login(this.userCredentials).subscribe(
      (loginResponse) => {
        this.responseFromLogin = loginResponse // assign login response
        
        if(loginResponse.challengeName &&
          !loginResponse.token && loginResponse?.challengeName === 'MFA_INTERACTION_REQUIRED') {
            this.loginPage = true;
            this.mfaInteractionRequired = true;
            this.mfaFirstActivationView = true;
            this.mfaForceEnterPhoneNumber = false;
            this.mfaForceEnterOTP = false;
            this.forceChangePassword = false;
            this.loginChallengeName = loginResponse?.challengeName;
            this.loginSessionToken = loginResponse?.session;
            this.loginSubmitting = false;
        }

        // if the user has enabled MFA and not registered the phone number, then 'CUSTOM_CHALLENGE_PHONE_NUMBER' says to enter a phone number
        else if (loginResponse?.challengeName === 'CUSTOM_CHALLENGE_PHONE_NUMBER') {
          this.loginPage = true;
          this.mfaInteractionRequired = false;
          this.mfaForceEnterPhoneNumber = true;
          this.mfaForceEnterOTP = false;
          this.forceChangePassword = false;
          this.loginChallengeName = loginResponse?.challengeName;
          this.loginSessionToken = loginResponse?.session;
          this.loginSubmitting = false;
        }

        // If the user has enabled MFA, then the response will give 'SMS_MFA' to enter OTP
        else if (loginResponse.challengeName &&
          loginResponse.session &&
          !loginResponse.token && loginResponse?.challengeName === 'SMS_MFA'
        ) {
          this.loginPage = true;
          this.mfaInteractionRequired = false;
          this.mfaForceEnterPhoneNumber = false;
          this.mfaForceEnterOTP = true;
          this.forceChangePassword = false;
          this.loginChallengeName = loginResponse?.challengeName;
          this.loginSessionToken = loginResponse?.session;
          this.mfaPartialPhoneNumber = loginResponse?.phoneNumber;
          this.loginSubmitting = false;
        }
        // If the user is newly created and logging in first time with the default password sent to the email, then he is forced to change password with the challengeName 'NEW_PASSWORD_REQUIRED'
        else if (
          loginResponse.challengeName &&
          loginResponse.session &&
          !loginResponse.token && loginResponse?.challengeName === 'NEW_PASSWORD_REQUIRED'
        ) {
          this.loginPage = true;
          this.loginChallengeName = loginResponse.challengeName;
          this.loginSessionToken = loginResponse.session;
          this.forceChangePassword = true;
          this.mfaForceEnterPhoneNumber = false;
          this.mfaForceEnterOTP = false;
          this.mfaInteractionRequired = false;

          /**
           * loginSubmitting is done false because when there is forceChangePassword true,
           * the change password UI will be enabled, which further hits the same login API
           */
          this.loginSubmitting = false;
        }
        else {
          if(this.mfaFirstActivationView && event?.mfaCode) {
            this.toastr.success('Successfully Enabled');
          }

          if (!event) {
            this.forceChangePassword = false;
            this.mfaForceEnterPhoneNumber = false;
            this.mfaForceEnterOTP = false;
            this.mfaInteractionRequired = false;
            this.mfaFirstActivationView = false;
          }

          /**
           * since the UUID is obtained from login API,no need t depend on me API to get UUID and also
           * we can make Activate FaceID/TouchID to work for other apps redirection too
           *  */
          if(navigator.userAgent.indexOf('gonative') > -1 && loginResponse?.uuid) {
            /**
             * as for non-business user, the me API gives logged user is not a business user error, the uuid cannot be saved for them,
             * so faceid login settings won't appear in the header. For this, the set the user's uuid and save in localStorage.
             */
            this.authService.setUUID(loginResponse?.uuid)
            this.handleOneSignalData()
          } else {
            this.handleLoginRedirection()
          }
        }
      },
      (error) => {
        this.loginSubmitting = false;
        var errorArray = error && error.errors ? error.errors[0].message : null;
        const errMessage =  errorArray || error.message || error.statusText;
        this.loginPage = true;
        this.errorMessage =
          event?.mfaCode && errMessage.includes("Invalid code")
            ? `Invalid code. Please try again or request a new code.`
            : errMessage;

        /**
         * if inside gonative and login API failed due to invalid username or password, then disable biometric and enable manual login.
         */
        if (navigator.userAgent.indexOf("gonative") > -1) {
          let setup = this.authService.getBiometricStatus();
          if (
            setup?.setup &&
            setup?.enabled &&
            errMessage === "User not authorised. Incorrect username or password."
          ) {
            this.errorMessage = `${errMessage} Please login to resume Face/Touch ID.`;
            this.invalidLoginCreds = true;
            this.isBiometricLogin = false; // manual login begins
          }
        }
      }
    );
  }

  async handleOneSignalData() {
    // this.toastr.info('i am here');
    let error = null;

    /**
     *
     * In eachperson, window.open is used in "Pick a reward", page.
     * In ios devices, When window.open is used, after logging out, the onesignal script is not found so the promise does not get executed.
     * For this, a timeout of 5 seconds is set. If the data or error is not received within 5 seconds, then a timeout throws an error.
     * It helps us to identify what to do next.
     *
     * But now, we are implementing a second approach here, which stores the onesignal data to localStorage on first fetch.
     *
     * If the script does not execute until 5 seconds, then use onesignal data stored in localStorage.
     * But if the data is not found in localStorage, then display the timeout error.
     */
    // call our API to save oneSignal info for the user and redirect
    const finalAction = (onesignaldata: any) => {
      // this.toastr.success(`gonative ${JSON.stringify(onesignaldata)}`);
      this.authService
      .postUserDataForOneSignal(this.responseFromLogin?.uuid, onesignaldata)
      .pipe(
        finalize(() => {
          // this.toastr.success('onesignal finalize');
          this.handleGonativeLogin();
        })
      )
      .subscribe(
        (success) => {
          // this.toastr.success('Successfully registered onesignal info for push notification.');
        },
        (error) => {
          this.toastr.error(`Error while registering your data for push notification: ${error}`);
        }
      );
    }

    // onesignal promise fuction check
    const fetchOneSignalData = async () => {
      try {
        if (typeof gonative_onesignal_info === 'function') {
          return await gonative_onesignal_info();
        } else {
          throw new Error('gonative_onesignal_info is not defined');
        }
      } catch (error) {
        throw new Error('Failed to fetch OneSignal info: ' + error);
      }
    };

    const timeout = (ms) => {
      return new Promise((_, reject) => {
        setTimeout(() => {
          reject('Timeout occurred while fetching onesginal data from device');
        }, ms);
      });
    };

    // fetch onesignal data from localStorage
    let gonativeData = this.authService.getGonativeData();

    const recursive = async (retries = 5, delay = 1000) => {
      // this.toastr.error('I am called');
      let oneSignalData = null;
      try {
        oneSignalData = await fetchOneSignalData();
      } catch (oneSignalError) {
        // alert(`failed fetching onesignal info ${retries}`);
        if (retries > 0) {
          setTimeout(() => {
            recursive(retries - 1, delay);
          }, delay);
        } else {
          error = oneSignalError + retries;
        }
      }

      if (error) {
        this.toastr.error('Failed to fetch onesignal info! Proceed redirection');
        setTimeout(() => {
          this.handleGonativeLogin();
        }, 3000)
      } else if (oneSignalData) {
        // save data to localStorage
        // this.toastr.success('Found onesignal data from Gonative.')
        gonativeData.oneSignalData = oneSignalData
        this.authService.storeGonativeData(gonativeData)
        finalAction(gonativeData?.oneSignalData)
      }
    };

    try {
      await Promise.race([recursive(), timeout(5000)]);
    } catch (error) {
      // if timeout error and found oneSignalData from localStorage, then use that data
      if(gonativeData?.oneSignalData) {
        // this.toastr.success('line 352: '+JSON.stringify(gonativeData?.oneSignalData))
        finalAction(gonativeData?.oneSignalData)
      } else{
        // Handle the error or timeout here
        this.toastr.error(error);
        this.authService.destroyLoginSession()
        this.loginPage = true
        this.errorMessage = 'Sorry, something went wrong while fetching data for push notification. Please close and re-open the app.'
      }
    }
  }

  async handleGonativeLogin() {
    // create object for the class GonativeGlobal
    let gonativeGlobalClass = new GonativeGlobal();

    let setup = this.authService.getBiometricStatus();

    // Check if biometric setup is available
    if (setup.setup) {
      // sometimes the auth function might not execute with no success or error, in that case use Promise.race
      let authStatusPromise = gonativeGlobalClass.checkBiometricStatus();

      let authStatus = null;
      try {
        authStatus = await Promise.race([authStatusPromise, new Promise((resolve) => setTimeout(resolve, 3000))]);
      } catch (error) {
        // Handle the error from checkBiometricStatus() if it doesn't execute successfully
        // this.toastr.error('Failed to execute checkBiometricStatus:', error);
        authStatus = null;
      }

      /*
       *
       * user's password can be changed from anywhere in the device. So lets not just depend on credentialsChanged = true value.
       * whenever the user manually logs in, and if biometric is activated and enabled and the uuid of the user logging in matches
       * with the device's uuid saved, then save the credentials again
       */
      if (
        setup.enabled &&
        (setup.credentialsChanged || !setup.credentialsChanged) &&
        setup.uuid === this.responseFromLogin?.uuid
      ) {
        // if manual login and uuid matched, resave
        if (!this.isBiometricLogin) {
          if (!authStatus || (authStatus && !authStatus?.hasTouchId)) {
            // this.toastr.info('Go to settings of your device and enable biometric authentication.')
            setTimeout(() => {
              this.handleLoginRedirection();
            }, 500);
          } else {
            setCred({
              username: this.userCredentials?.email,
              password: this.userCredentials?.password,
              uuid: this.responseFromLogin?.uuid,
            });

            const deviceInfo = await gonativeGlobalClass.loadDeviceInfo();
            // alert('device: '+ JSON.stringify(deviceInfo))
            let _data = {
              deviceId: deviceInfo.installationId,
              deviceInformation: JSON.stringify(deviceInfo),
              uuid: this.responseFromLogin?.uuid,
              credentialStatus: false,
              biometricStatus: true,
            };

            this.authService.saveBiometricsData(_data).subscribe(
              async (res) => {
                const secretSaved = await gonativeGlobalClass.saveSecret();
                setup.enabled = true;
                setup.setup = true;
                setup.credentialsChanged = false;
                setup.uuid = this.responseFromLogin?.uuid;
                this.authService.setBiometrics(setup);
                // this.toastr.success('Credentials updated for biomeric login.')

                setTimeout(() => {
                  this.handleLoginRedirection();
                }, 500);
              },
              (err) => {
                this.toastr.error('Failed to save details : ' + JSON.stringify(err));
                this.handleLoginRedirection();
              }
            );
          }
        } else {
          this.handleLoginRedirection();
        }
      } else {
        // if there is biometric setup then login
        this.handleLoginRedirection();
      }
    } else {
      let checkBiometricsExecuted = false;
      let checkBiometricsPromise = gonativeGlobalClass.checkBiometricStatus();

      try {
        checkBiometricsExecuted = await Promise.race([checkBiometricsPromise, new Promise((resolve) => setTimeout(resolve, 3000))]);
      } catch (error) {
        // Handle the error from checkBiometricStatus() if it doesn't execute successfully
        // this.toastr.error('Failed to execute checkBiometrics:', error);
      }

      if (checkBiometricsExecuted) {
        this.loginPage = true;
        this.askForBiometricsSetup(this.responseFromLogin?.uuid);
      } else {
        this.handleLoginRedirection();
      }
    }
  }



  handleLoginRedirection() {
    // if no rediret_uri but state is there in the URL, then it should be the eachperson's internal URL
    if (!this.redirect_uri && this.state) {
      this.authService.saveCognitoState(this.state);
    }
    
    if (this.responseFromLogin.token.access_token) {
      this.onAuthencationSuccess();
    }
  }

  onAuthencationSuccess() {
    this.authService.removeSsoUser(); // once received fresh tokens as normal user --> remove viaSso flag from localStorage

    this.fetchMeAPI();
  }

  fetchMeAPI() {
    // need fresh data from API so deleting cache me data
    this.authService.deleteMeCacheItem();
    this.authService.getMe()
      .pipe(take(1)) // take(1) is used to run the subscription once and not leak the subscription
      .subscribe(
        (res) => {
          this.loginPage = false;
          this.loginSubmitting = false;
          if (res) {
            /**
             * here fetchMeAPI is used for accounts redirection too.
             * In case of accounts redirection, common function doRedirection is not used because this might not give the expected result
             */
            // if the login is accessed from any other app and has redirect_uri, also state
            if (
              this.redirect_uri &&
              !(this.response_type || this.response_type === "token")
            ) {
              this.authService.removeOtherAppsRedirectURI(); // remove from localStorage

              //to get authenticationCode
              this.authService
                .seamlessLogin(this.authService.getRefreshToken())
                .subscribe((responseCode) => {
                  let redirectionUrl = `${this.redirect_uri}?code=${
                    responseCode.authenticationCode
                  }${this.state ? `&state=${this.state}` : ""}`;
                  
                  if(this.redirect_uri === getWPShopDomain() || this.redirect_uri ===  `${getWPShopDomain()}/`) {
                    let shop_url = this.redirect_uri === getWPShopDomain() ? `${this.redirect_uri}/` : this.redirect_uri;
                    redirectionUrl = `${shop_url}?code=${
                      responseCode.authenticationCode
                    }${this.state ? `&redirect_to=${encodeURIComponent(this.state)}` : ""}`;
                  }
                  window.location.href = redirectionUrl;
                }, error => {
                  this.loginSubmitting = false;
                  this.errorMessage = error
                });
            } else {
              this.authService.doRedirection(res);
            }
          }
        },
        (error) => {
          if(error === 'Logged user is not a business user' || error === "Your account is locked. Please contact Each Person.") {
            // do nothing - handled in auth.service.ts already
          } else {
            console.log('login err: ', error)
            this.loginPage = true;
            this.loginSubmitting = false;
            this.authService.destroyLoginSession();
            this.errorMessage = error || 'Unable to fetch user detail. Please contact Each Person.'
          }
        }
      );
  }

  // onEmailConfirmed is used when an email field is filled up
  onEmailConfirmed(event: any) {
    this.emailConfirmed = true;
    this.loginForm.controls["email"].patchValue(event.email);
    this.errorMessage = "";
  }

  // onBiometric event is used, when the faceid/touchid prompt is shown and the biometric matches
  onBiometricLogin(event: any) {
    // start: for gonative - biometric login
    if (event?.email && event?.password){
      this.loginPage = false;

      // emailConfirmed is set to true because the user who enabled MFA should see MFA code enter window after biometric login
      this.emailConfirmed = true;
      this.isBiometricLogin = true;
      this.loginFormSubmit(event);
    }
    // end: for gonative - biometric login
  }

  // if got the credentialStatus from check-sso-signin component - then change the text
  onCredentialsChanged(event: boolean) {
    this.credentialsChanged = event
  }

  handleOtherAppsSsoRedirection() {
    let accountDomain = getAccountsBaseURL();
    let companyShortName = this.authService.getCompanyShortName();
    if(this.redirect_uri && this.redirect_uri.includes(accountDomain)) {
      let splitAccountsUrl = this.redirect_uri.split(`${accountDomain}/`);
      window.location.replace(`${accountDomain}/sso?companyShortName=${companyShortName}${splitAccountsUrl.length === 2 && splitAccountsUrl[1] ? `&redirectUrl=${splitAccountsUrl[1]}` : ''}`)
    } else {
      window.location.replace(this.redirect_uri)
    }
  }

  askForBiometricsSetup(uuid: string){
    setCred({
      username: this.userCredentials?.email,
      password: this.userCredentials?.password,
      uuid
    })
    this.displayBiometricsSetup = true
  }
}
