import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import {
  HttpClient, HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from "ngx-dropzone";
import {
  PerfectScrollbarConfigInterface, PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CustomUrlSerializer } from 'src/app/core/_helpers/CustomUrlSerializer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CheckRedirectionComponent } from './authentication/check-redirection/check-redirection.component';
import { GdprComponent } from './authentication/gdpr/gdpr.component';
import { NoAccessComponent } from './authentication/no-access/no-access.component';
import { ContactUsChatWidgetComponent } from './contact-us/contact-us-chat-widget/contact-us-chat-widget.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CoreModule } from './core/core.module';
import { GlobalErrorHandler } from './core/error-handlers/global-error-handler';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { ErrorInterceptor } from './core/interceptor/error.interceptor';
import { AddHttpHeadersInterceptor } from './core/interceptor/add-http-headers.interceptor';
import { HttpCancelInterceptorInterceptor } from './core/interceptor/http-cancel-interceptor.interceptor';
import { ToastrCustomMessageService } from './core/service/toastr-custom-message.service';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { RightSidebarComponent } from './layout/right-sidebar/right-sidebar.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { SharedModule } from './shared/shared.module';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { LoginComponent } from './authentication/login/login.component';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { MagicLinkComponent } from './authentication/magic-link/magic-link.component';
import { CheckEmailComponent } from './authentication/check-email/check-email.component';
import { ForceChangePasswordComponent } from './authentication/force-change-password/force-change-password.component';
import { PasswordlessLoginVerifyComponent } from './authentication/passwordless-login-verify/passwordless-login-verify.component';
import { CheckSsoSigninComponent } from './authentication/check-sso-signin/check-sso-signin.component';
import { LoginHandlerComponent } from './authentication/login-handler/login-handler.component';
import { SsoVerificationComponent } from './authentication/sso-verification/sso-verification.component';
import { EAPComponent } from './eap/eap.component';
import { FaceIdComponent } from './authentication/face-id/face-id.component';
import { SpecseversEmployeeComponent } from './specsevers-employee/specsevers-employee.component';
import { SpecsaversEmployeeFormComponent } from './specsevers-employee/specsavers-employee-form/specsavers-employee-form.component';
import { SpecsaversEmployeeFormSummaryComponent } from './specsevers-employee/specsavers-employee-form-summary/specsavers-employee-form-summary.component';
import { SpecsaversEmployeeSuccessComponent } from './specsevers-employee/specsavers-employee-success/specsavers-employee-success.component';
import { GpOnDemandComponent } from './gp-on-demand/gp-on-demand.component';
import { SidebarMobileComponent } from './layout/sidebar/sidebar-mobile/sidebar-mobile.component';
import {NotificationComponent} from './layout/header/notification/notification.component';
import { CustomTranslateLoader } from '../app/shared/custom-loader';
import { BenefitsComponent } from './benefits/benefits.component';
import { BenefitsExpandedComponent } from "./benefits/benefits-expanded/benefits-expanded.component";
import { BenefitsFormComponent } from "./benefits/benefits-form/benefits-form.component";
import { EarnedWageAccessEmployeeComponent } from './earned-wage-access-employee/earned-wage-access-employee.component';
import { HotjarFeedbackComponent } from './shared/components/hotjar-feedback/hotjar-feedback.component';
import { MicrosoftCallbackComponent } from './authentication/microsoft-callback/microsoft-callback.component';
import { Page404Component } from './authentication/page404/page404.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { MfaComponent } from './authentication/mfa/mfa.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BenefitsReminderComponent } from './benefits-reminder/benefits-reminder.component';
import { AccountLockedComponent } from './authentication/account-locked/account-locked.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SpecseversEmployeeComponent,
    SpecsaversEmployeeFormComponent,
    SpecsaversEmployeeSuccessComponent,
    SpecsaversEmployeeFormSummaryComponent,
    NotificationComponent,
    SidebarComponent,
    RightSidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    FooterComponent,
    ContactUsComponent,
    ContactUsChatWidgetComponent,
    NoAccessComponent,
    CheckRedirectionComponent,
    GdprComponent,
    ForgotPasswordComponent,
    LoginComponent,
    ChangePasswordComponent,
    MagicLinkComponent,
    CheckEmailComponent,
    ForceChangePasswordComponent,
    PasswordlessLoginVerifyComponent,
    CheckSsoSigninComponent, // declare this component here otherwise sharedmodules (like feather icons) won't work for the component
    LoginHandlerComponent,
    SsoVerificationComponent,
    EAPComponent,
    FaceIdComponent,
    GpOnDemandComponent,
    SidebarMobileComponent,
    BenefitsComponent,
    BenefitsExpandedComponent,
    BenefitsFormComponent,
    EarnedWageAccessEmployeeComponent,
    MicrosoftCallbackComponent,
    HotjarFeedbackComponent,
    Page404Component,
    LogoutComponent,
    BenefitsReminderComponent,
    AccountLockedComponent,
    MfaComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxIntlTelInputModule,
    PerfectScrollbarModule,
    NgxDropzoneModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    // core & shared
    CoreModule,
    SharedModule,
    NgbModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center'
    })
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHttpHeadersInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }, // to handle errors caused due to deployment of new changes in the server
    /**
     * as the toastr services dreclty or indirectly depends on other services, these should be included in the providers.
     * Otherwise, circular dependency injection error will appear in the browser's console.
     * These can either be included in the providers of the components or
     * in the modules where the components (in which these services are to be used) belong
     *
     * Note: As the modules are lazy loaded, the services used in the providers of app.module.ts seem not to be working for the lazy loaded modules
     * example: these services can be used in user-management.module.ts or in hr.module.ts
     */
     ToastrService,
     ToastrCustomMessageService
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}