import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    if(!lang) {
      return of(null);
    }
    // S3 URL
    const s3Url = `https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/dashboard-i18n/${lang}.json`;

    // Try to fetch from S3, fall back to local file on error
    return this.http.get(s3Url, { headers }).pipe(
      catchError(() => {
        console.error(`Failed to load translations from S3. Falling back to local file for language: ${lang}`);
        /**
         * here in case of error no language is set so the texts are shown blank so in order to not show blank 
         * and return null so that in case of null, default html texts are shown
         */
        return of(null);
      })
    );
  }
}
