import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, FormControl, FormGroup, Validators } from "@angular/forms";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.sass']
})
export class MfaComponent implements OnInit {
  @Input('mfaInteractionRequired') mfaInteractionRequired: boolean = false;
  @Input('mfaForceEnterPhoneNumber') mfaForceEnterPhoneNumber: boolean = false;
  @Input('mfaForceEnterOTP') mfaForceEnterOTP: boolean = false;
  @Input('errorMessage') errorMessage: string = '';
  @Input('loginSubmitting') loginSubmitting: boolean = false;
  @Input('currentYear') currentYear: number;
  @Input('mfaFirstActivationView') mfaFirstActivationView: boolean = false;
  @Input('email') email: string = '';
  @Input('mfaPartialPhoneNumber') mfaPartialPhoneNumber: string = '';

  @Output() mfaAction: EventEmitter<any>= new EventEmitter();
  mfaInteractionForm: FormGroup;
  phoneForm: FormGroup;
  mfaForm: FormGroup;

  mfaInteractionEnterPhoneNumber: boolean = false;

  selectedCountryCode = '+44'; // Default country code
  selectedCountry = 'gb'; // Default selected country ISO

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];
  customPlaceHolder: string = 'e.g. 07400 123456';
  countdown: number = 60;
  private intervalId: any;
  
  constructor() { }

  ngOnInit(): void {
    this.mfaInteractionForm = new FormGroup({
      mfaStatus: new FormControl(false, [Validators.required]),
      phoneNumber: new FormControl(""),
      otpCode: new FormControl(null), 
    });

    this.mfaInteractionForm.get('mfaStatus').valueChanges
    .subscribe(val => {
      if(val) {
        this.mfaInteractionForm.get('phoneNumber').setValidators([Validators.required]);
        this.mfaInteractionForm.get('phoneNumber').updateValueAndValidity();
      } else {
        this.mfaInteractionForm.get('phoneNumber').setValidators(null);
        this.mfaInteractionForm.get('phoneNumber').updateValueAndValidity();
      }
    });

    if(this.mfaForceEnterOTP && this.mfaFirstActivationView) {
        this.mfaInteractionForm.get('otpCode').setValidators([Validators.required]);
        this.mfaInteractionForm.get('otpCode').updateValueAndValidity();
    } else {
      this.mfaInteractionForm.get('otpCode').setValidators(null);
      this.mfaInteractionForm.get('otpCode').updateValueAndValidity();
    }

    this.phoneForm = new FormGroup({
      phoneNumber: new FormControl("", [Validators.required])
    });

    this.mfaForm = new FormGroup({
      otpCode: new FormControl(null, [Validators.required]),
    });

    if(this.mfaForceEnterOTP && !this.mfaFirstActivationView) {
      this.startCountdown();
    }
  }

  ngOnChanges(): void {
    if (this.mfaForceEnterOTP) {
      this.startCountdown();
    }
  }

  submitForceMfa(resendCode?: boolean, skipped?: boolean) {
    this.errorMessage = null;
    let payload = null;
    if(this.loginSubmitting) {
      return false;
    }

    if(this.mfaInteractionRequired) {
      payload = {
        mfaStatus: skipped ? 'NONE' : (this.mfaInteractionForm.get('mfaStatus').value ? 'ENABLED' : 'DISABLED'),
        phoneNumber: this.mfaInteractionForm.get('phoneNumber').value ? this.mfaInteractionForm.get('phoneNumber').value?.e164Number : ""
      };
    }
    else if(this.mfaForceEnterPhoneNumber) {
      payload = {
        phoneNumber: this.phoneForm.get('phoneNumber').value ? this.phoneForm.get('phoneNumber').value?.e164Number : ""
      };
    }

    // OTP code should not be sent in the payload while resending code as this can give error and no otp will be sent
    else if (!resendCode && this.mfaForceEnterOTP && this.mfaFirstActivationView) {
      payload = {
        mfaCode: this.mfaInteractionForm.get('otpCode').value 
      };
    }
    else if (!resendCode && this.mfaForceEnterOTP && !this.mfaFirstActivationView) {
      payload = {
        mfaCode: this.mfaForm.get('otpCode').value 
      };
    }
    if(!payload && !resendCode) {
      return false;
    }
    
    this.mfaAction.emit(payload);
  }

  onCountryChange(event: any) {
    this.customPlaceHolder = `e.g. ${event.placeHolder}`;
    this.selectedCountryCode = `+${event.dialCode}`; // Update country code
    this.selectedCountry = event.iso2; // Update the selected country ISO
  }

  toggleMfaStatus(val: boolean) {
    if(!val) {
      this.mfaInteractionEnterPhoneNumber = val;
    } 
  }

  mfaStatusConfirm() {
    let status = this.mfaInteractionForm.get('mfaStatus').value;
    if(!status) {
      this.submitForceMfa();
    } else {
      this.mfaInteractionEnterPhoneNumber = true;
    }
  }

  closeMfaWindow() {
    window.location.reload();
  }

  resendMfaCode() {
    // clear the OTP form field
    this.mfaInteractionForm.get('otpCode').setValue(null);
    this.mfaForm.get('otpCode').setValue(null);
    this.countdown = 60;
    this.startCountdown();
    this.submitForceMfa(true);
  }

  private startCountdown(): void {
    // Clear any existing countdown to avoid multiple timers
    clearInterval(this.intervalId);

    // Initialize countdown timer
    this.intervalId = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(this.intervalId); // Stop the timer when countdown reaches 0
      }
    }, 1000); // Update every second
  }

  ngOnDestroy(): void {
    // Clear interval when the component is destroyed
    clearInterval(this.intervalId);
  }

}
