import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators}  from 'src/app/core/_helpers/validators/customized.validator';

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.scss']
})
export class ForceChangePasswordComponent implements OnInit {
  @Output() firstTimePassword: EventEmitter<any>= new EventEmitter();

  forceChangePassword: FormGroup;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  @Input('errorMessage') errorMessage: string = '';
  @Input('loginSubmitting') loginSubmitting: boolean = false;
  @Input('currentYear') currentYear: number = null;

  constructor(private formBuilder: FormBuilder,) {
    this.forceChangePassword = this.createForceChangePassword();
   }

  ngOnInit(): void {
  }

  createForceChangePassword(): FormGroup {
    return this.formBuilder.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])],
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.mustMatch('password', 'confirmPassword')
      }
    );
  }

  submitForceChangePassword(){
    this.errorMessage = null;
    let payload = {
      changedPassword: this.forceChangePassword.get('password').value 
    };
    this.firstTimePassword.emit(payload);
  }





  changeKeyType(togBool){
    switch (togBool) {
      case 'pass' : this.showPassword = !this.showPassword;break;
      case 'cPass' : this.showConfirmPassword = !this.showConfirmPassword;break;
    }
  }

  errorCatcher(type: string, control: string): boolean{
    return this.forceChangePassword.controls[control].hasError('required') || this.forceChangePassword.controls[control].hasError(type)
  }
}



