import { Injectable } from "@angular/core";
import { HttpClient, HttpBackend, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, defer, Observable, of } from "rxjs";
import { catchError, map, shareReplay, tap, take, retryWhen, delay, concatMap } from "rxjs/operators";
import { User, UserFlags } from "../models/user";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import {
  getAlternativeRedirectURI,
  getSSOLoginURL,
  getSSOLogoutURL,
  getWPShopDomain,
} from "../_helpers/environment-variables";
import { throwError } from "rxjs/internal/observable/throwError";
import { deleteEPCookie, encryptToken, linkTargetViaGoNative, setEPCookie } from "../_helpers/global-functions";
import { GonativeGlobal } from "../../../assets/custom-JS/gonative-global-class";
import { NgxSpinnerService } from "ngx-spinner";
@Injectable({
  providedIn: "root",
})
export class AuthService{
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private userFlagsSubject: BehaviorSubject<UserFlags>;
  public userFlags: Observable<UserFlags>;
  state: string = null; // cognito state
  meApiCache: any;

  cred: any = null;
  uuid: string = null;

  // injecting this class in constructor threw error so created class object instance.
  goNativeClass = new GonativeGlobal();
  // Key for caching the 'me' API observable
  private cacheKey = 'meApiCache';

  constructor(
    private http: HttpClient,
    private router: Router,
    private httpBackend: HttpBackend,
    private spinner: NgxSpinnerService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.meApiCache = {};

    // user flags observable
    this.userFlagsSubject = new BehaviorSubject<UserFlags>(null);
    this.userFlags = this.userFlagsSubject.asObservable();
  }

  // generic login API for normal users
  login(payload: any) {
    let url = environment.apiUrl;

    let httpWithoutInterceptor = new HttpClient(this.httpBackend)
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const requestOptions = { headers };
    return httpWithoutInterceptor
    .post<any>(`${url}/user-authentication/login`, payload, requestOptions)
      .pipe(
        map((response) => {
          if (response.token) {
            this.storeLoginSession(response.token);
            setEPCookie('is_sso', 'false');
          }

          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err?.error);
        })
      );
  }
  passwordLessSignIn(email: string, verificationCode: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/authenticate-magic-link`, {
        email: email,
        code: verificationCode,
      })
      .pipe(
        map((response) => {
          let logindata = {
            access_token: response.accessToken,
            refresh_token: response.refreshToken,
            expires_in: response.expiresIn,
          };
          this.storeLoginSession(logindata);
          return response;
        })
      );
  }

  passwordLessSendEmail(email: string) {
    return this.http.post<any>(`${environment.apiUrl}/send-magic-link`, {
      email: email,
    });
  }

  seamlessLogin(refreshTokenValue: string) {
    let httpWithoutInterceptor = new HttpClient(this.httpBackend)
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const requestOptions = { headers };
    return httpWithoutInterceptor.post<any>(
      `${environment.apiUrl}/user-authentication/exchange-code`,
      { refreshToken: refreshTokenValue },
      requestOptions
    ).pipe(
      map(response => {
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        var errorArray = err.error && err.error.errors ? err.error.errors[0].message : null;
        const error =  errorArray || err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  seamlessLoginPromise(refreshTokenValue: string): Promise<any> {
    let httpWithoutInterceptor = new HttpClient(this.httpBackend);
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const requestOptions = { headers };

    // Convert Observable to Promise using toPromise() method
    return httpWithoutInterceptor.post<any>(
      `${environment.apiUrl}/user-authentication/exchange-code`,
      { refreshToken: refreshTokenValue },
      requestOptions
    ).toPromise()
    .catch((err: HttpErrorResponse) => {
      var errorArray = err.error && err.error.errors ? err.error.errors[0].message : null;
      const error =  errorArray || err.error.message || err.statusText;
      return Promise.reject(error);
    });
  }

  getTokenFromCode(code: string) {
    let httpWithoutInterceptor = new HttpClient(this.httpBackend)
    return httpWithoutInterceptor.get<any>(
      `${environment.apiUrl}/user-authentication/get-token`,
      { params: {code} }
    ).pipe(
      catchError((err: HttpErrorResponse) => {
        var errorArray = err.error && err.error.errors ? err.error.errors[0].message : null;
        const error =  errorArray || err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // set uuid
  setUUID(uuid?: string) {
    this.uuid = uuid
  }

  getUUID() {
    return this.uuid
  }

  // function to store userdetail to localStorage
   setUser(data: any) {
    /**
     * in order to show login settings in header for non-business user. For non-business user, the me api fails, so no uuid is saved in
     * currentUser detail in localStorage. so set and get uuid from login api.
     */
    let storedUser = this.currentUserValue
    if(!data?.businessUser && !storedUser?.id && this.getUUID() && navigator.userAgent.indexOf('gonative') > -1) {
      data['id'] = this.getUUID()
    }
    if(navigator.userAgent.indexOf('gonative') > -1) {
      if (data?.shopOnly){
        this.goNativeClass.removeBottomNav()
      }
    }
    localStorage.setItem("currentUser", JSON.stringify(data));
    this.currentUserSubject.next(data);

    // for shop to improve performance
    setEPCookie('user_type', data?.userType);
  }

  /**
   * this function is used when you have to check user specially after login and do redirection
   * in case of sso verification and check cognito login after success response from authenticate-token API
   */
  checkUser() {
    // need fresh data from API so deleting cache me data
    this.deleteMeCacheItem();

    this.getMe()
      .pipe(take(1)) // take(1) is used to run the subscription once and not leak the subscription
      .subscribe(
        (res: any) => {
          if (res) {
            this.doRedirection(res);
          }
        },
        (error: any) => {
          if (error && error === "Access is denied") {
            // do nothing --> it will be handled by error.interceptor.ts
          } else {
            if (this.getLoginSession().token) {
              // checkUser() failed, token exists and do normal redirection
              let user = JSON.parse(localStorage.getItem("currentUser"));
              this.doRedirection(user);
            } else {
              // checkUser() failed, token does not exist and go to login screen
              this.handleCognitoLoginRedirection();
            }
          }
        }
      );
  }

  decodeURL(url: string) {
    let decoded = decodeURIComponent(url);
    return decoded;
  }

  // redirect to the pages after successful login
  doRedirection(userdetail: any) {
    if (!userdetail) {
      this.handleCognitoLoginRedirection();
      return;
    }

    let otherAppRedirection = this.getOtherAppsRedirectURI();
    const cognitoState = this.getCognitoState();
    const decodedState = cognitoState ? this.decodeURL(cognitoState) : null;

    if (cognitoState) {
      this.router.navigateByUrl(decodedState);
      this.state = null;
      return;
    }

    if (userdetail) {
      if (!userdetail.businessUser || (userdetail.status && userdetail.status !== "active")) {
        /**
         * here if the user is deleted in eachperson or the user is not a business user,
         * and has response_type token saved with redirect_uri in localStorage, then the user should be redirected to the shop.
         * Otherwise, the user should be taken to no-access page
         **/
        if(otherAppRedirection?.redirect_uri && otherAppRedirection?.response_type === "token") {
          this.tokenBasedRedirection()
          return
        }
        this.router.navigate(["/no-access"]);
        return;
      } else if (!userdetail.gdprAccepted) {
        this.router.navigate(["/join/gdpr"]);
        return;
      } else if (
        userdetail?.businessUser &&
        userdetail?.gdprAccepted &&
        otherAppRedirection?.redirect_uri &&
        otherAppRedirection?.response_type === "token"
      ) {
        // set token in the cookie
        if(this.getLoginSession()?.token && this.getLoginSession()?.refreshToken) {
          setEPCookie('access_token', encryptToken(this.getLoginSession()?.token));
          setEPCookie('refresh_token', encryptToken(this.getLoginSession()?.refreshToken));
        }
        if (
          otherAppRedirection?.state &&
          this.checkShopOnly(otherAppRedirection?.state) &&
          userdetail?.shopOnly
        ) {
          this.shopOnlyUserRedirection();
          return;
        }
        if (!otherAppRedirection?.state && userdetail?.shopOnly) {
          this.shopOnlyUserRedirection();
          return;
        }
        this.tokenBasedRedirection();
        return;
      } else if (!userdetail.setUpCompleted) {
        if (userdetail.stepsCompleted === 1) {
          this.router.navigate(["/hr/config/ecard-templates"]);
          return;
        } else if (userdetail.stepsCompleted === 2 || userdetail.stepsCompleted > 3) {
          this.router.navigate(["/hr/config/reasons"]);
          return;
        } else if (userdetail.stepsCompleted === 3) {
          this.router.navigate(["/hr/payment/select"]);
          return;
        } else {
          this.router.navigate(["/set-up"]);
          return;
        }
      }
    }

    this.router.navigate(["/hr/dashboard"]);
  }


  /**
   * common method for redirection
   * used when query parameter has response_type=token
   * 
   * as of 16th May 2024, do not pass code or token in the URL as it is set in the cookie
   */
  tokenBasedRedirection() {
    let redirectionObj = this.getOtherAppsRedirectURI()
    this.removeOtherAppsRedirectURI()
    window.location.href = `${redirectionObj?.redirect_uri}${redirectionObj?.state ? `?redirect_to=${encodeURIComponent(redirectionObj?.state)}` : ""}`;
  }


  // function to check userdetail on each navigation change and update the localStorage
  checkMe() {
    console.log(
      "token expiry datatime: ",
      this.getTokenExpirationDate(this.getLoginSession()?.token)
    );
    this.getMe().pipe(take(1)).subscribe();
  }

  getMeCacheItem() {
    let cacheItem = this.meApiCache[this.cacheKey];

    if (!cacheItem) {
      return null;
    }

    // Delete the cache item if it has expired
    if (cacheItem.expires <= Date.now()) {
      this.deleteMeCacheItem();
      return null;
    }
    return cacheItem?.observable;
  }

  setMeCacheItem(observableValue): void {
    // 1 sec = 1000
    const EXPIRES = Date.now() + 60 * 1000; // Cache expiry time set to 1 minute
    this.meApiCache[this.cacheKey] = { expires: EXPIRES, observable: observableValue };
  }

  deleteMeCacheItem() {
    delete this.meApiCache[this.cacheKey];
  }

  setUserLocked() {
    localStorage.setItem('isUserLocked', JSON.stringify(true));
  }

  getIsUserLocked() {
    return JSON.parse(localStorage.getItem('isUserLocked'));
  }

  removeUserLockStatus() {
    localStorage.removeItem('isUserLocked');
  }

  // get the detail of logged-in user
  getMe() {
    /**
     * me API is called multiple times in the app - on page change, on refresh
     * this causes API request load in the back-end.
     * In order to reduce the multiple API requests:
     * 1. We cache the data.
     * 2. Set the expiry time to 1 minute.
     * 3. If the API is requested again within 1 minute, return the cached data.
     */
    const cachedObservable = this.getMeCacheItem();

    if (cachedObservable) {
      return cachedObservable; // Return cached data if available
    }

    let token = this.getLoginSession();
    const httpWithoutInterceptor = new HttpClient(this.httpBackend);
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${token.token}`,
    });

    const requestOptions = { headers: headers };

    // Function to make the actual API request
    const makeRequest = () => {
      return httpWithoutInterceptor.get<any>(`${environment.apiUrl}/users/me`, requestOptions).pipe(
        tap((response) => {
          this.setUser(response);
          // Cache the successful response
          this.setMeCacheItem(of(response));
          return response;
        }),
        catchError((err: any) => {
          var errorArray = (err?.error && err?.error?.errors) ? err?.error?.errors[0].message : null;
          const error = errorArray || err?.error?.message || err?.statusText;
          if (error && error === "Logged user is not a business user") {
            let user = { businessUser: false };
            this.setUser(user);
            this.router.navigate(["/no-access"]).then(() => {
              this.spinner.hide();
            });

            // Cache the user object to avoid multiple API calls
            this.setMeCacheItem(of(user));

            // Return the user object to avoid returning undefined
            return of(user);
          } else if(error && error === "Your account is locked. Please contact Each Person.") {
            this.setUserLocked();

            if(this.getLoginSession()?.token && this.currentUserValue?.id) {
              this.logout().then(
                (res) => {
                  if(res?.logOutUrl) {
                    this.setCustomLogoutURL(res.logOutUrl);
                  }
                  this.destroyLoginSession();
                  this.router.navigate(['/user-locked']).then(() => {
                    window.location.reload();
                  });
                }
              );
            } else {
              this.destroyLoginSession();
              this.router.navigate(['/user-locked']).then(() => {
                window.location.reload();
              });
            }

            return of(null);
            
          }

          // Handle other errors and remove cache
          this.deleteMeCacheItem();
          return throwError(error);
        }),
        shareReplay(1) // Ensure the response is shared with multiple subscribers
      );
    };

    const observable = defer(() => makeRequest()).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          concatMap((err) => { // this gives error thrown from catchError
            if ((err && err === "Access is denied") || (this.getLoginSession() && this.isTokenExpired())) {
              return this.refreshToken().pipe(
                concatMap((token: any) => {
                  // Update headers with new token
                  requestOptions.headers = new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: `${token.access_token}`,
                  });
                  // Re-run the API request with the updated headers
                  return makeRequest();
                })
              );
            } else {
              // If it's not the 'Access is denied' error, throw the error to prevent retry
              return throwError(err);
            }
          }),
          delay(500)
        );
      }),
      shareReplay(1) // Ensure the response is shared with multiple subscribers
    );

    // Cache the observable to prevent multiple API calls
    this.setMeCacheItem(observable);
    return observable;
  }

  // new api from user related flags
  /**
   * this API is called globally on sidebar component and the observable is used everywhere
   * except in the guards. Because, the guard runs before the API call and since the data is not stored in localStorage,
   * the obseravabled in the guard does not find the data and does not work.
   * For this, the API is called inside the guard.
   */
  getMeFlags() {
    let token = this.getLoginSession();
    const httpWithoutInterceptor = new HttpClient(this.httpBackend);
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${token.token}`,
    });
    let requestOptions = {headers: headers};

    return defer(() => {
      return httpWithoutInterceptor.get<any>(`${environment.apiUrl}/users/me/flags`, requestOptions);
    }).pipe(
      map(res => {
        this.userFlagsSubject.next(res)
        return res;
      }), catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }), retryWhen((error) => {
        return error.pipe(
          delay(500),
          tap(() => {
            requestOptions.headers = new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: `${this.getLoginSession()?.token}`,
            });
          }),
          take(10)
        );
      })
    );
  }

  destroyLoginSession() {
    // remove user from local storage to log user out

    // do not remove gonativeData from localStorage
    let gdata = this.getGonativeData()
    // localStorage.clear();
    this.temporaryLogout();
    this.currentUserSubject.next(null);
    deleteEPCookie(); // remove cookie set for the shop on logout

    if(navigator.userAgent.indexOf('gonative') > -1) {
      this.storeGonativeData(gdata)
    }
  }

  logout() {
    return this.http
      .get<any>(`${environment.accessTokenAPI}/global-logout`)
      .toPromise();
  }

  // user in manual logout from header
  initiateLogout() {
    this.logout()
    .then(res => {
      if(res?.logOutUrl) {
        console.log('logout URL found: ', res.logOutUrl);
        this.setCustomLogoutURL(res.logOutUrl);
      }
      this.handleCognitoLogoutRedirection();
    })
  }

  setCustomLogoutURL(url: string) {
    localStorage.setItem("customLogoutUrl", url);
  }

  getCustomLogoutURL() {
    return localStorage.getItem("customLogoutUrl");
  }

  removeCustomLogoutURL() {
    return localStorage.removeItem("customLogoutUrl");
  }

  handleCognitoLogoutRedirection() {
    let ssoLogoutUrl = getSSOLogoutURL(); // if not assigned in variable then using method directly will give undefined as the session will be destroyed
    let isSsoUser = this.getSsoUser();
    this.destroyLoginSession();
    if(isSsoUser) {
      window.location.href = ssoLogoutUrl;
    } else {
      let customLogout = this.getCustomLogoutURL();
      if(customLogout) {
        this.removeCustomLogoutURL();
        window.location.href = customLogout;
      } else {
        this.router.navigate(["/login"]).then(() => {
          window.location.reload();
        });
      }
    }
  }

  /**
   * cognito auth --> for sso user, after login hit this api to verify code and get the auth tokens
   */
  getAuthTokens(code: string, companyShortName: string = null) {
    let redirect = environment.cognitoRedirectUrl;
    if (companyShortName) {
      redirect = getAlternativeRedirectURI(companyShortName);
    }

    let queryParams = {
      code,
      redirect,
    };

    if (companyShortName) {
      queryParams["companyShortName"] = companyShortName;
    }
    return this.http
      .get<any>(`${environment.accessTokenAPI}/authenticate-token`, {
        params: queryParams,
      })
      .pipe(
        map((response) => {
          this.storeLoginSession(response);
          setEPCookie('is_sso', 'true');
          
          console.log("real login auth response received");
          return response;
        })
      )
  }

  // function to hit our BE API for one signal push notification
  postUserDataForOneSignal(uuid: string, gn_OneSignalUserData: any) {
    const httpWithoutInterceptor = new HttpClient(this.httpBackend);
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    const requestOptions = { headers: headers };
    // gn_OneSignalUserData = {
    //     oneSignalUserId: 'xxxxxxx',
    //     oneSignalPushToken: 'xxxxxx',
    //     oneSignalSubscribed: true,
    //     oneSignalRequiresUserPrivacyConsent: false,
    //     platform: 'ios',
    //     appId: 'io.gonative.example',
    //     appVersion:  '1.0.0',
    //     distribution: 'release',
    //     hardware: 'armv8',
    //     installationId: 'xxxx-xxxx-xxxx-xxxx',
    //     language: 'en',
    //     model: 'iPhone',
    //     os: 'iOS',
    //     osVersion: '10.3',
    //     timeZone: 'America/New_York'
    // }
    gn_OneSignalUserData['internalUserId'] = uuid
    return httpWithoutInterceptor.post<any>(
      `${environment.apiUrl}/app-user`,
      gn_OneSignalUserData,
      requestOptions
    );
  }

  storeLoginSession(data: {
    access_token: string;
    refresh_token: string;
    expires_in: number;
  }) {
    localStorage.setItem("TOKEN", JSON.stringify(data.access_token));
    localStorage.setItem("REFRESH_TOKEN", JSON.stringify(data.refresh_token));
    // localStorage.setItem("TOKEN_EXPIRY", JSON.stringify(data.expires_in));
    setEPCookie('access_token', encryptToken(data?.access_token));
    setEPCookie('refresh_token', encryptToken(data?.refresh_token));
  }

  // function to store the access_token in the localStorage that is received after successfully refreshing the token
  storeRefreshAccessToken(data) {
    localStorage.setItem("TOKEN", JSON.stringify(data.access_token));
    setEPCookie('access_token', encryptToken(data?.access_token));
    if(data.refresh_token) {
      localStorage.setItem("REFRESH_TOKEN", JSON.stringify(data.refresh_token));
      setEPCookie('refresh_token', encryptToken(data?.refresh_token));
    }
  }

  getRefreshToken() {
    return JSON.parse(localStorage.getItem("REFRESH_TOKEN"));
  }

  getLoginSession() {
    return {
      token: JSON.parse(localStorage.getItem("TOKEN")),
      refreshToken: JSON.parse(localStorage.getItem("REFRESH_TOKEN")),
      // tokenExpiry: JSON.parse(localStorage.getItem("TOKEN_EXPIRY"))
    };
  }

  // API to check token's validity
  checkTokenValidity() {
    const token = this.getLoginSession();
    const httpWithoutInterceptor = new HttpClient(this.httpBackend);
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${token.token}`,
    });
    const requestOptions = { headers: headers };
    return httpWithoutInterceptor.get<any>(
      `${environment.accessTokenAPI}/user/validate-token`,
      requestOptions
    );
  }

  /**
   * API to refresh token when the token is expired
   */
  refreshToken() {
    let isSsoUser = this.getSsoUser();

    const httpWithoutInterceptor = new HttpClient(this.httpBackend);

    // if isSsoUser, then send companyShortName in queryParams
    if (isSsoUser) {
      let params = {
        redirect: location.origin,
      };
      params.redirect = getAlternativeRedirectURI(this.getCompanyShortName());
      params["companyShortName"] = this.getCompanyShortName();

      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        token: this.getRefreshToken(),
      });

      const requestOptions = {
        params,
        headers,
      };
      return httpWithoutInterceptor
        .get<any>(
          `${environment.accessTokenAPI}/exchange-token`,
          requestOptions
        )
        .pipe(
          map((user) => {
            this.storeRefreshAccessToken(user);
            setEPCookie('is_sso', 'true');
            return user;
          }),
          catchError((err) => {
            console.log("without interceptor - refresh token failed");
            // in this case, make user temporarily logged out with the previous page saved
            // so that he can get back to the same page after logged back in
            // if sso user then show sso logout interface else normal logout interface
            this.handleTemporaryLogout();
            return throwError(err);
          })
        );
    } else {
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
      });
      const requestOptions = {
        headers,
      };

      const data = {
        refreshToken: this.getRefreshToken(),
      };
      return httpWithoutInterceptor
        .post<any>(
          `${environment.apiUrl}/user-authentication/exchange-token`,
          data,
          requestOptions
        )
        .pipe(
          map((user) => {
            this.storeRefreshAccessToken(user);
            setEPCookie('is_sso', 'false');
            return user;
          }),
          catchError((err) => {
            console.log(
              "without interceptor custom API - refresh token failed"
            );
            // in this case, make user temporarily logged out with the previous page saved
            // so that he can get back to the same page after logged back in
            // if sso user then show sso logout interface else normal logout interface
            this.handleTemporaryLogout();
            return throwError(err);
          })
        );
    }
  }

  // get the expiration date from the access_token
  getTokenExpirationDate(token: string): Date {
    const jwtToken = JSON.parse(atob(token.split(".")[1]));
    const expires = new Date(jwtToken.exp * 1000);
    return expires;
  }

  // check if the token is expired
  isTokenExpired() {
    let token = this.getLoginSession().token;
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (!date) return true;
    return !(date.valueOf() > new Date().valueOf());
    // if(date.getTime() - Date.now() < 0)
    //   return true;
    // else return false;
  }

  // split url to get query params
  getSplitUrl() {
    let url = location.search;
    let query = url.substr(1);
    let result = {};
    query.split("&").forEach((part) => {
      if (part) {
        let item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      }
    });
    return result;
  }

  // store returnUrl received from external domain
  storeReturnUrl(epointsReturnUrl: string, eachpersonUrl: string) {
    localStorage.setItem("returnUrl", JSON.stringify(epointsReturnUrl));
  }

  /**
   * returns returnUrl value from the localStorage
   * used inorder to redirect to the external domain such as epoints.com
   *
   * used by no-access component only till now
   */
  getReturnUrl() {
    return JSON.parse(localStorage.getItem("returnUrl"));
  }

  /**
   * this function is used to save the eachperson's route as a history to redirect back to the same page
   * but only when the user is not logged in or when the user has returnUrl
   * if the redirectBackUrl is saved everytime the user changes the route, the conflict might be seen in redirection
   * eachperson's route which was tried to access when logged out, once logged in the user should be redirected to the desired page.
   * this also checks the returnUrl, stores the value, returnUrl is basically used by other domains, that accesses the eachperson app.
   * In order to return to the domain URL from where eachperson was accessed, the returnUrl value is used.
   * if there is returnUrl in parameter then returnUrl is saved else only redirectBackUrl is saved.
   *
   * when manually logged out, both the values will be cleared, they will be same when auto logged out.
   */
  checkUrlRoute() {
    /**
     * check returnUrl parameter in the URLs.
     * returnUrl is the parameter with the value of the route from where eachperson is redirected
     * returnUrl value can be epoints domain with path from where eachperson is accessed
     * store the returnUrl in localStorage
     * redirect back to the stored returnUrl
     * if the user accesses eachperson app from epoints and goes to no-access page,
     * then should be able to redirect back
     * returnUrl contains the value of the external domain
     * if there is, then save the url to localStorage
     * so that the user can return back URL placed in the returnUrl value whenever required
     * this is used by the epoints app to redirect to eachperson with the returnUrl param
     * so that the user can redirect back to the same page left in the epoints app.
     * here checking URL routes using this.route.queryParams for
     * some routes such as hr/dashboard, hr/history/users-allocation can be accessed only when logged in
     * due to AuthGuard used in app-routing.module.ts
     * so check location.search instead
     */
    let result = this.getSplitUrl();
    if (result && result.hasOwnProperty("returnUrl")) {
      let returnUrlValue = result["returnUrl"];
      let finalReturnUrl = returnUrlValue;
      /**
       * the getSplitUrl function breaks the ?protected and true so check
       * 1. if only ?protected includes in the url not with true value
       * 2. if ?protected is there with value true but there are multiple ?protected=true because
       * the redirection from accounts and shop is added the parameter everytime
       * if there are multiple protected parameters, replace all and add only single protected parameter
       */
      if (
        returnUrlValue.includes("?protected") &&
        !returnUrlValue.includes("?protected=true")
      ) {
        finalReturnUrl = returnUrlValue.includes("protected=false")
          ? returnUrlValue.replaceAll("?protected=false", "")
          : `${returnUrlValue}=true`;
      } else if (
        returnUrlValue.includes("?protected") &&
        returnUrlValue.includes("?protected=true")
      ) {
        finalReturnUrl = returnUrlValue.replaceAll("?protected=true", "");
        finalReturnUrl = `${finalReturnUrl}?protected=true`;
      }
      this.storeReturnUrl(finalReturnUrl, location.pathname);
    }
  }

  /**
   * if recognised sso route
   */
  storeSsoUser(data: any) {
    localStorage.setItem("viaSso", JSON.stringify(data));
  }

  getSsoUser() {
    return JSON.parse(localStorage.getItem("viaSso"))?.enabled;
  }

  getCompanyShortName(): string {
    return JSON.parse(localStorage.getItem("viaSso"))?.companyShortName;
  }

  removeSsoUser() {
    localStorage.removeItem("viaSso");
  }

  // set cognito state
  saveCognitoState(state: any) {
    this.state = state;
  }

  // get cognito state
  getCognitoState() {
    return this.state;
  }

  // set redrect_uri obtained from other apps in localStorage
  saveOtherAppsRedirectURI(otherAppRedirectURI: string, otherAppState: string, response_type: string) {
    if (
      otherAppRedirectURI ||
      otherAppState ||
      (response_type && otherAppRedirectURI)
    ) {
      let data = {};
      if (otherAppRedirectURI) {
        data["redirect_uri"] = otherAppRedirectURI === getWPShopDomain() ? `${getWPShopDomain()}/` : otherAppRedirectURI;
      }
      if (otherAppState) {
        data["state"] = otherAppState;
      }
      if (response_type) {
        data["response_type"] = response_type;
      }
      localStorage.setItem("otherAppRedirection", JSON.stringify(data));
    }
  }

  // get stored other app's redirection data
  getOtherAppsRedirectURI() {
    let otherAppsRedirection = JSON.parse(localStorage.getItem("otherAppRedirection"));
    let queryParams = {
      redirect_uri: null,
      state: null,
      response_type: null,
    };
    if (otherAppsRedirection?.redirect_uri) {
      queryParams.redirect_uri = otherAppsRedirection?.redirect_uri;
    }
    if (otherAppsRedirection?.state) {
      queryParams.state = otherAppsRedirection?.state;
    }
    if (otherAppsRedirection?.response_type) {
      queryParams.response_type = otherAppsRedirection?.response_type;
    }

    return queryParams;
  }

  removeOtherAppsRedirectURI() {
    localStorage.removeItem("otherAppRedirection");
  }

  // this encodeRoute function might be called every time, so declare stateParameter outside of the the function
  // instead of inside the function as this function may be called every time.
  stateParameter = null;
  encodeRoute() {
    let path = location.pathname;
    if (
      path !== "/login" &&
      path !== "/login-verify" &&
      path !== "/" &&
      path !== "/404" &&
      path !== "/no-access" &&
      path !== '/login-handler' &&
      path !== '/seamless-redirection' &&
      path !== '/user-locked'
    ) {
      // this.router.url does not work to the routes that need user's info to get access when logged out
      let queries = location.search;
      this.stateParameter = queries
        ? encodeURIComponent(path + queries)
        : path;
    }

    // if there is state parameter && if the redirection happened due to login failure or something
    if (path === "/login-verify" || path === "/sso") {
      console.log("check login-verify or sso with state in param");
      let result = this.getSplitUrl();
      if (result && result.hasOwnProperty("state")) {
        this.stateParameter = encodeURIComponent(result["state"]);
      } else if(result && result.hasOwnProperty('redirectUrl')) {
        this.stateParameter = encodeURIComponent(`/${result['redirectUrl']}`);
      } else {
        this.stateParameter = null;
      }
    }

    return this.stateParameter;
  }

  // handle cognito login redirection
  // this is used when there is no token, it means when the user is not logged in
  // if not logged in - but check if there is viaSso flag in localStorage
  handleCognitoLoginRedirection() {
    /**
     * whenever the user tries to access the route when not logged in, the user is redirected to cognito login
     * for that this function is called
     * so steps to follow
     * 1. detect FE URL trying to access -> when not logged in
     * 2. add the url (encode if needed) in the cognito login parameter - state
     * 3. check for state in the login-verify or sso route --> after logged in decode state and redirect
     */

    let state = this.encodeRoute();
    if (this.getSsoUser()) {
      let ssoLoginURL = state
        ? `${getSSOLoginURL()}&state=${state}`
        : getSSOLoginURL();
      window.location.href = ssoLoginURL;
    } else {
      this.router.navigate(["/login"], { queryParams: { state } });
    }
  }

  removeLoginTokens() {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("REFRESH_TOKEN");
  }

  temporaryLogout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem('biometrics');
    localStorage.removeItem('treesDonated');
    this.removeLoginTokens();
    this.removeOtherAppsRedirectURI();
    this.currentUserSubject.next(null);
    this.removeSsoUser();
    deleteEPCookie(); // remove cookie set for the shop on logout
  }

  handleTemporaryLogout() {
    // in this case, make user temporarily logged out with the previous page saved
    // so that he can get back to the same page after logged back in
    // if sso user then show sso logout interface else normal logout interface
    let state = this.encodeRoute();

    this.temporaryLogout();
    if (state) {
      // window.location.replace(`${location.origin}/login?state=${state}`)
      this.router.navigate(["/login"], { queryParams: { state } }).then(() => {
        window.location.reload();
      });
    } else {
      this.router
        .navigate(["/login"], { queryParamsHandling: "merge" })
        .then(() => {
          window.location.reload();
        });
    }
  }

  checkEmailForSso(email: string) {
    return this.http.post<any>(`${environment.apiUrl}/users/data`, {
      emailId: email,
    });
  }

  getBiometricStatus(){
    let _bio = localStorage.getItem('biometrics') ? JSON.parse(localStorage.getItem('biometrics')) :
    {
      enabled: false, setup: false, uuid: '', credentialsChanged: false
    }
    return _bio
  }

  setBiometrics(data: any){
    localStorage.setItem('biometrics', JSON.stringify(data))
  }

  saveBiometricsData(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/user-biometric`, data);
  }

  getBiometricsData(id: any) {
    return this.http.get<any>(`${ environment.apiUrl }/user-biometric/${id}`);
  }

  storeGonativeData(data: any) {
    localStorage.setItem('gonativeData', JSON.stringify(data))
  }

  getGonativeData() {
    let gData = localStorage.getItem('gonativeData') ? JSON.parse(localStorage.getItem('gonativeData')) :
    {
      deviceInfo: null, oneSignalData: null
    }
    return gData
  }

  insideGoNativeWrapper(){
    return linkTargetViaGoNative()
  }

  // shop only
  checkShopOnly(URL: string = null) {
    /**
     * shoponly - if the user comes from shop and intended_url has exact shop domain, then check for shoponly user
     * and redirect accordingly.
     *
     * else redirect users wherever they want to
     */
    let checkShopOnlyUser = false
    let splitIntendedUrl = URL ? URL.split('?') :  []
    if(splitIntendedUrl.length > 0 && splitIntendedUrl[0] === `${getWPShopDomain()}/`) {
      checkShopOnlyUser = true
    }
    return checkShopOnlyUser
  }

  /**
   * if the user is a shopOnly user, this function will be called and redirect to the required page as the
   * data provided by the me API.
   */
  async shopOnlyUserRedirection() {
    const data = JSON.parse(localStorage.getItem("currentUser") || "{}");
    if(navigator.userAgent.indexOf('gonative') > -1) {
      if (data?.shopOnly){
        this.goNativeClass.removeBottomNav()
      }
    }

    let shopUrl = this.currentUserValue?.dieselCardUser
    ? `${getWPShopDomain()}/?redirect_to=${encodeURIComponent(`${getWPShopDomain()}/diesel-card/`)}`
    : `${getWPShopDomain()}/`;
    window.location.href = shopUrl;
  }
}