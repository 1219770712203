import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-account-locked',
  templateUrl: './account-locked.component.html',
  styleUrls: ['./account-locked.component.sass']
})
export class AccountLockedComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(!this.authService.getIsUserLocked()) {
      this.router.navigate(['/hr/dashboard']).then();
    }
  }

  handleRedirection() {
    this.authService.handleCognitoLogoutRedirection();
  }

}
