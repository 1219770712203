<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
    <div class="container">
        <section  class="d-flex align-items-center justify-content-center">
            <div class="section-body">
                <div class="row">
                    <div class="col-12">
                        <div class="card text-center mb-0" style="max-width: 350px;">
                            <div class="card-body m-auto">
                                <img width="160px" alt="logo" [src]="'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png'" />
                                <hr>
                                <h4 class="page-title">Account Locked</h4>
                                <img class="mt-2 mb-3" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/rebranding-images/lock.svg">
                                <p class="px-lg-2">Your account is locked. Please contact Each Person at <a href="mailto:support@eachperson.com">support@eachperson.com</a>.</p>
                                <button type="button" class="btn btn-secondary btn-reward" (click)="handleRedirection()">Go to login page</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
  </main>
  